<template>
  <div class="download">
    <img-div
      class-name=""
      height="700"
      :img-url="require('@/assets/download/maskBg.png')"
    >
      <div class="container flex">
        <div class="downLink">
          <div class="linkItem flex flex-vc flex-hc">
            <img class="linkIcon" src="@/assets/download/apple.png" alt="" />
            <span>{{ $t("download.down1.name") }}</span>
            <el-popover placement="right" width="200" trigger="hover">
              <div class="rightTop" slot="reference">
                <img class="erCode" src="@/assets/download/erCode.png" alt="" />
              </div>
              <div class="downCode">
                <div class="codeTitle">{{ $t("download.down1.popTitle") }}</div>
                <img src="@/assets/download/ios_code.png" alt="" />
              </div>
            </el-popover>
          </div>

          <div class="linkItem flex flex-vc flex-hc">
            <img class="linkIcon" src="@/assets/download/android.png" alt="" />
            <span>{{ $t("download.down2.name") }}</span>
            <el-popover placement="right" width="200" trigger="hover">
              <div class="rightTop" slot="reference">
                <img class="erCode" src="@/assets/download/erCode.png" alt="" />
              </div>
              <div class="downCode">
                <div class="codeTitle">{{ $t("download.down2.popTitle") }}</div>
                <img src="@/assets/download/android_code.png" alt="" />
              </div>
            </el-popover>
          </div>
          <a
            href="https://qkzq-public.oss-cn-shenzhen.aliyuncs.com/OpenAPI.zip"
            target="_blank"
          >
            <div class="linkItem flex flex-vc flex-hc">
              <img class="linkIcon" src="@/assets/download/pc.png" alt="" />
              <span>{{ $t("download.api.name") }}</span>
            </div>
          </a>
        </div>
        <div>
          <img
            src="../../assets/download/bannerSlogan.png"
            class="slogan"
            alt=""
          />
        </div>
      </div>
    </img-div>
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
export default {
  components: {
    ImgDiv,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.download {
  background-color: var(--whiteColor);
  .downLink {
    padding-left: 95px;
    padding-top: 170px;
    .linkItem {
      width: 280px;
      height: 80px;
      border: 1px solid var(--whiteColor);
      border-radius: 10px;
      color: var(--whiteColor);
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 60px;
      overflow: hidden;
      position: relative;
      .linkIcon {
        width: 60px;
      }
      .rightTop {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-top: 60px solid var(--whiteColor);
        border-left: 60px solid transparent;
        .erCode {
          width: 20px;
          height: 20px;
          display: block;
          position: absolute;
          right: 7px;
          top: -53px;
        }
      }
    }
  }
  .slogan {
    width: 650px;
    margin-top: 92px;
    margin-left: 110px;
  }
}
.downCode {
  padding: 3px;
  .codeTitle {
    color: var(--erCodeTitleColor);
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }
  img {
    margin-top: 5px;
    display: block;
    width: 100%;
  }
}
</style>
